import { css } from '@emotion/react'
import styled from '@emotion/styled'
import type { PaymentMethod } from '@orus.eu/backend/src/views/payment-view'
import {
  Avatar,
  ButtonLink,
  Text,
  borderStroke,
  colorTokens,
  shadow,
  spacing,
  useScreenType,
  useTranslate,
  type CompoundIconName,
} from '@orus.eu/pharaoh'
import type { LocalizableMessage } from '@orus.eu/translations'
import { memo, type FunctionComponent } from 'react'
import { CardBody } from './card-body'
import { ContainedIcon } from './contained-icon'

export const PaymentMethodCard = memo<{
  paymentMethod: PaymentMethod
}>(function PaymentMethodCard({ paymentMethod }) {
  const translate = useTranslate()
  const { title, details, iconName } = paymentMethodDataInfoForUi[paymentMethod.type](paymentMethod)

  return (
    <Card>
      <Avatar icon={iconName} size="50" />
      <div>
        <Text variant="body2Medium">{translate(title)}</Text>
        <Text variant="body2">{translate(details)}</Text>
      </div>
    </Card>
  )
})

const Card = styled.div`
  display: flex;
  padding: ${spacing[70]} ${spacing[60]};
  align-items: center;
  gap: ${spacing[60]};
  flex: 1 0 0;
  align-self: stretch;
  box-shadow: ${shadow.bottom[20]};
  border: ${borderStroke[20]} solid ${colorTokens['color-stroke-base']};
  border-radius: ${spacing[40]};
`

export const LegacyPaymentMethodCardBody: FunctionComponent<{
  paymentMethod: PaymentMethod | null
}> = memo(function PaymentMethodBodyCard({ paymentMethod }): JSX.Element {
  const translate = useTranslate()
  const screenType = useScreenType()
  const { title, details, iconName } = paymentMethod
    ? paymentMethodDataInfoForUi[paymentMethod.type](paymentMethod)
    : noPaymentMehtodInfo
  const isMobile = screenType === 'mobile'
  return (
    <CardBody
      css={css`
        display: flex;
        align-items: ${!isMobile ? 'center' : undefined};
        justify-content: ${!isMobile ? 'space-between' : undefined};
        flex-direction: ${isMobile ? 'column' : undefined};
        gap: ${isMobile ? spacing[50] : undefined};
        box-shadow: ${shadow.bottom[20]};
        border: ${borderStroke[20]} solid ${colorTokens['color-stroke-base']};
        border-radius: ${spacing[40]};
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: ${spacing[50]};
        `}
      >
        <ContainedIcon icon={iconName} color={colorTokens['color-fg-base-active-inverse']} />
        <div>
          <Text variant="body2Medium">{translate(title)}</Text>
          <Text
            variant="body2"
            css={css`
              margin-top: ${spacing[30]};
            `}
          >
            {translate(details)}
          </Text>
        </div>
      </div>

      <ButtonLink
        variant="text"
        icon="pen-clip-regular"
        to="/payment-methods/new"
        avatarPosition="left"
        css={css`
          color: black;
        `}
      >
        {translate('payment_method_replace')}
      </ButtonLink>
    </CardBody>
  )
})

type PaymentDataInfoForUi = {
  title: LocalizableMessage
  details: LocalizableMessage
  iconName: CompoundIconName
}

const paymentMethodDataInfoForUi: {
  [paymentMethodType in PaymentMethod['type']]: (paymentMethod: PaymentMethod) => PaymentDataInfoForUi
} = {
  card: (paymentMethod) => ({
    title: 'payment_method_cb_description',
    details: {
      key: 'payment_method_cb_details',
      parameters: { last4: paymentMethod.last4 },
    },
    iconName: 'credit-card-front-regular',
  }),
  sepa_debit: (paymentMethod) => ({
    title: 'payment_method_sepa_description',
    details: {
      key: 'payment_method_sepa_details',
      parameters: { last4: paymentMethod.last4 },
    },
    iconName: 'building-columns-regular',
  }),
}

const noPaymentMehtodInfo: PaymentDataInfoForUi = {
  title: 'payment_method_none_description',
  details: 'payment_method_none_details',
  iconName: 'diamond-exclamation-solid',
}
